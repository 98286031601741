<script>
    import LoginHeader from "~/components/LoginHeader.svelte";

    export let title = "";
</script>

<div>
    <LoginHeader {title} />
    <div class="box_content">
        <slot />
    </div>
</div>

<style>
    div .box_content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 4px;
    }

    div .box_content :global(p), div .box_content :global(form) {
        margin-bottom: 0;
    }
</style>
